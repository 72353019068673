<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/PromotionManage/exchangeManage">兑换码管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{$route.query.operation == 1 ? '新建':'编辑' }}兑换码</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">兑换码名称：</span>
        <div class="right_Div">
          <a-input :class="{'pointerEvents' : batchId}" class="all_input" v-model="batchName" placeholder="请输入兑换码名称" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">适用商品：</span>
        <div class="right_Div">
          <a-auto-complete
            style="width:337px"
            :class="{'pointerEvents' : batchId}"
            v-model="productForm.name"
            placeholder="输入搜索关键字"
            option-label-prop="value"
            @search="onSearchRole"
            :defaultActiveFirstOption="false"
          >
            <a-spin
              v-if="fetching"
              class="auto-spin"
              slot="notFoundContent"
              size="small"
            />
            <template slot="dataSource">
              <a-select-option
                @click="onSelectRole(item)"
                v-for="(item, index) in list"
                :key="index + ''"
                :value="item.productName"
              >
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col>{{ item.productName }}</a-col>
                </a-row>
              </a-select-option>
            </template>
          </a-auto-complete>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">价值：</span>
        <div class="right_Div">
          <a-input-number class="all_input" :class="{'pointerEvents' : batchId}" prefix="￥" v-model="payPrice" :min=0 placeholder="请配置价格" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">库存：</span>
        <div class="right_Div">
          <a-input-number class="all_input" :class="{'pointerEvents' : batchId}" v-model="num" :min=0 placeholder="请配置库存量" />
          <p class="tips">注：确认库存数量后，将生成对应数量的兑换码</p>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name ">应用渠道：</span>
        <div class="right_Div">
          <a-input class="all_input" :class="{'pointerEvents' : batchId}" v-model="channel" placeholder="请输入应用渠道" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name ">使用说明：</span>
        <div class="right_Div">
          <a-textarea :class="{'pointerEvents' : batchId}" :auto-size="{ minRows: 3 }" v-model="notes" class="all_input" placeholder="请输入使用说明" />
        </div>
      </div>
    </div>

    <div class="foot-btn">
      <a-button class="btn" @click="$router.go(-1)">取消</a-button>
      <a-button type="primary" :loading="loading" @click="onSave" v-show="!batchId">确认</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      batchId:0,
      loading:false,
      fetching: false,
      // 商品参数
      productForm: {
        noGround: 1, // 商品上架状态1.上架 0下架
        name:'',
        page:1,
        size:10,
        noExam: 0 // 是否查询模拟考试商品 1查 0 不查
      },
      list:[],
      batchName:'', // 兑换码名称
      productId: 0, // 商品id
      payPrice:'', // 价值
      num:'', // 库存
      channel:'', // 渠道
      notes:'', // 使用说明
    }
  },
  // 事件处理器
  methods: {
    // 打印选中商品
    onSelectRole(e) {
      this.productId = e.productId;
    },
    // 检索商品
    onSearchRole(val) {
      this.fetching = false;
      this.list = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this.productId = 0;
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/product-archives/manage/select-win",
                params: _this.productForm,
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  _this.list = res.data.records;
                  _this.fetching = false;
                }
              });
          }, 400);
        }
      });
    },

    // 新建兑换券
    onSave(){
      if(!this.batchName){
        this.$message.warning("请输入兑换码名称");
        return
      }
      if(!this.productId){
        this.$message.warning("请选择适用商品");
        return
      }
      if(!this.payPrice){
        this.$message.warning("请输入兑换券价格");
        return
      }
      if(!this.num){
        this.$message.warning("请输入库存量");
        return
      }
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/redeem/add',
        method: 'post',
        params: {
          batchName: this.batchName,
          productId: this.productId,
          productName: this.productForm.name,
          payPrice: this.payPrice,
          num: this.num,
          channel: this.channel,
          notes: this.notes,
        }
      }).then(res=>{
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success('创建成功');
          this.$router.go(-1);
        } else {
          this.$message.warning(res.message);
        }
      })
    },

    // 获取详情
    getDetail(){
      this.$ajax({
        url: '/hxclass-management/redeem/getDetail',
        params: {
          id: this.batchId,
        }
      }).then(res=>{
        if (res.code == 200 && res.success) {
          let obj = res.data
          this.batchName = obj.batchName
          this.productForm.name = obj.productName
          this.payPrice = obj.payPrice
          this.num = obj.inventoryNum
          this.channel = obj.channel
          this.notes = obj.notes
          
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.batchId = this.$route.query.batchId;
    if(this.batchId){
      this.getDetail()
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.all_content_box {
  .tips{
    margin-top: 5px;
    width: 100%;
    font-size: 14px;
    color: #cccccc;
  }
  .L_R_inner {
    margin: 24px;

    .all_left_name {
      width: 190px;
      text-align: right;
    }

    .right_Div {
      .textarea.ant-input {
        width: 800px;
        height: 100px;
      }

      .all_input {
        width: 337px;
      }

      /deep/.ant-input {
        height: 37px;
      }
      /deep/ .ant-input-number-input {
        line-height: 37px;
        height: 37px;
        padding: 0;
      }


      .else_input {
        margin-right: 12px;
        width: 140px;
      }

      .left {
        margin-left: 12px;
      }
    }
  }

  .pointerEvents{
    pointer-events:none;
  }
}
.foot-btn{
  margin-left: 130px;
  .btn{
    margin-right: 30px;
  }
}
</style>
